/** @format */
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { Button, ButtonVariant } from "@roadflex/react-components-www";
import { TestimonialsType } from "@roadflex/types";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

const HorizontalCard = ({
  imageSrc,
  testimonial,
  author,
  company,
  caseStudyUrl,
  redirectTo,
}) => (
  <div className="flex flex-col max-w-[500px] lg:max-w-none w-full overflow-hidden bg-white rounded-lg shadow-md  border-2 lg:flex-row border-gray-100">
    <div className="w-full h-full max-w-[600px]">
      {imageSrc ? (
        <Image
          src={imageSrc}
          priority={true}
          layout="responsive"
          width={750}
          height={500}
          quality={60}
          alt="Customer Company Image"
        />
      ) : (
        <></>
      )}
    </div>
    <div className="flex flex-col justify-between w-full p-6 xl:p-8 xl:px-10">
      <div className="flex flex-col gap-3 mb-2 xl:mt-6">
        <div className="relative w-5 h-5">
          <Image
            className="relative w-5 h-5"
            layout="fill"
            src={`${NEXT_PUBLIC_CDN_URL}/landing_page/svg/blockquote-1.svg`}
            alt={'"'}
          ></Image>
        </div>
        <blockquote className="text-gray-600 xl:text-lg">
          {testimonial}
        </blockquote>
        <div className="relative w-5 h-5 ml-auto">
          <Image
            className="relative w-5 h-5"
            layout="fill"
            src={`${NEXT_PUBLIC_CDN_URL}/landing_page/svg/blockquote-2.svg`}
            alt={'"'}
          ></Image>
        </div>
      </div>
      <div className="flex flex-row flex-wrap items-end justify-between gap-2">
        <div>
          <p className="mb-2">{author}</p>
          <p className="text-xl font-bold">{company}</p>
        </div>

        {company === "Appalachian Utility Services" && (
          <Button
            variant={ButtonVariant.AppOrange}
            className="font-bold !text-orange-500 rounded-full"
            onClick={() => redirectTo(caseStudyUrl)}
          >
            Read Case Study
            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
          </Button>
        )}
      </div>
    </div>
  </div>
);

const VerticalCard = ({
  imageSrc,
  testimonial,
  author,
  company,
  caseStudyUrl,
  fleetUpColorScheme,
  redirectTo,
  videoSrc,
  videoSrcImage,
}) => (
  <div className="overflow-hidden bg-white flex flex-col rounded-lg shadow-md max-w-[550px] w-full">
    <div className="relative mb-4">
      {videoSrc ? (
        <video
          className="object-cover md:h-[408.8px] w-full"
          poster={videoSrcImage}
          controls
          muted
          // onPause={handlePause}
          // onPlay={handlePlay}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      ) : imageSrc ? (
        <Image
          src={imageSrc}
          priority={true}
          layout="responsive"
          width={270}
          height={200}
          alt="Company Image"
        />
      ) : (
        <></>
      )}
    </div>
    <div className="flex flex-col justify-between h-full p-6 flex-grow-1">
      <div className="flex flex-col gap-3 mb-4">
        <div className="relative w-5 h-5">
          <Image
            className="relative w-5 h-5"
            layout="fill"
            src={`${NEXT_PUBLIC_CDN_URL}/landing_page/svg/blockquote-1.svg`}
            alt={'"'}
          ></Image>
        </div>
        <blockquote className="text-gray-600 ">{testimonial}</blockquote>
        <div className="relative w-5 h-5 ml-auto">
          <Image
            className="relative w-5 h-5"
            layout="fill"
            src={`${NEXT_PUBLIC_CDN_URL}/landing_page/svg/blockquote-2.svg`}
            alt={'"'}
          ></Image>
        </div>
      </div>
      <div className="flex flex-row flex-wrap items-center justify-between gap-4">
        <div>
          <p className="mb-2">{author}</p>
          {!fleetUpColorScheme && (
            <p className="text-xl font-bold">{company}</p>
          )}
          {fleetUpColorScheme && (
            <p className="text-xl font-bold text-fleetupBlue">{company}</p>
          )}
        </div>

        {company === "Appalachian Utility Services" && (
          <Button
            variant={ButtonVariant.AppOrange}
            className="font-bold !text-orange-500 rounded-full"
            onClick={() => redirectTo(caseStudyUrl)}
          >
            Read Case Study
            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
          </Button>
        )}
      </div>
    </div>
  </div>
);

type TestimonialsProps = {
  fleetUpColorScheme?: boolean;
  header: string;
  testimonials: TestimonialsType[];
  uiType: "vertical" | "horizontal";
};

export default function Testimonials(props: TestimonialsProps) {
  const router = useRouter();
  const redirectTo = (page) => {
    if (page !== "#") {
      router.push({
        pathname: `/${page}`,
      });
    }
  };
  const { fleetUpColorScheme = false, header, testimonials, uiType } = props;
  return (
    <div
      id="testimonials"
      className="flex items-center justify-center w-full my-14 md:my-32"
    >
      {uiType === "vertical" ? (
        <div className="container w-full">
          <div className="mb-16">
            <div className="flex items-center justify-center ">
              <div
                className={`flex items-center justify-center px-4 py-2 mb-8 text-sm font-bold text-white rounded-xl w-fit ${
                  !fleetUpColorScheme ? "bg-brown-500" : "bg-fleetupBlue"
                }`}
              >
                <div className="flex flex-row mr-3">
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                </div>
                Top-rated
              </div>
            </div>
            {!fleetUpColorScheme && (
              <div className="mb-8 text-3xl font-bold text-center text-brown-900 md:text-4xl 3xl:text-5xl">
                {header}
              </div>
            )}
            {fleetUpColorScheme && (
              <div className="mb-8 text-3xl font-bold text-center text-fleetupBlue md:text-4xl 3xl:text-5xl">
                {header}
              </div>
            )}
            <div className="text-base font-medium text-center">
              Fleet Owners, managers and finance leaders use RoadFlex to run
              efficient fleet operations
            </div>
          </div>
          <div className="">
            <div className="flex flex-col flex-wrap items-center justify-center gap-12 mx-auto md:items-stretch md:flex-row">
              {testimonials.map((card, idx) => (
                <VerticalCard
                  key={idx}
                  fleetUpColorScheme={fleetUpColorScheme}
                  redirectTo={redirectTo}
                  {...card}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="container w-full">
          <div className="flex flex-col items-center justify-center mx-auto gap-14 lg:gap-32 md:items-strech">
            {testimonials.map((card, idx) => (
              <HorizontalCard key={idx} {...card} redirectTo={redirectTo} />
            ))}
          </div>
        </div>
      )}{" "}
    </div>
  );
}
